import { Badge } from "../../components/Badge";
import styles from "./SecurityBadges.module.less";

export const SecurityBadges = () => {
  return (
    <div className={styles.SecurityBadges}>
      <Badge background="blue-1000">
        <strong>SOC 2 Type II</strong> Certified
      </Badge>
      <Badge background="blue-1000">
        <strong>GDPR</strong> Compliant
      </Badge>
      <Badge background="blue-1000">
        <strong>CCPA</strong> Compliant
      </Badge>
      <Badge background="blue-1000">
        <strong>FCRA</strong> Compliant
      </Badge>
      <Badge background="blue-1000">
        <strong>GLBA</strong> Compliant
      </Badge>
    </div>
  );
};
