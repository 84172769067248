export const meta = {
  homepage: {
    title: "Demyst: The External Data Management Platform",
    description:
      "Demyst enables data teams to manage all their external data. External data orchestration at scale, with pipelines that provide trusted external data.",
  },
  platform: {
    title: "The Demyst Platform",
    description:
      "Use Demyst to build your External Data Hub. Demyst provides the end-to-end platform you need to onboard and ingest the right external data into your Data Hub, with our experts working closely with you every step of the way.",
  },
  platform_discover: {
    title: "Data Discovery",
    description:
      "Access hundreds of fully integrated sources, or easily request something new with the Demyst Platform.",
  },
  platform_management: {
    title: "Maintenance & Support",
    description:
      "Simplify the management of all your external data with a single global interface to reduce maintenance burden and maximize value.",
  },
  platform_governance: {
    title: "Governance, Procurement & Compliance",
    description:
      "Say goodbye to the “Compliance Versus Speed” trade-off. Meet industry leading compliance practices whilst typically onboarding new data in four weeks or less.",
  },
  platform_deployment: {
    title: "Deployment",
    description: "Deploy up to ten times the data, ten times faster with the Demyst Platform.",
  },
  whydataops: {
    title: "Frictionless External Data",
    description:
      "Keeping pace with the expanding universe of external data is painful without the right technology supporting you.",
  },
  ccpa: {
    title: "CCPA",
    description: "Personal Information Request Form for California Residents",
  },
  dsar: {
    title: "DSAR",
    description: "Data Subject Action Request",
  },
  case_study: {
    title: "Resources",
    description:
      "Meet the industry leaders across the globe that are unlocking the power of external data with Demyst.",
  },
  about: {
    title: "About us",
    description:
      "Demyst believes that external data is the next frontier of business impact, able to power competitive advantages in industries around the world with improved decisioning, new products and better efficiencies.",
  },
  contact: {
    title: "Contact us",
    description:
      "Contact us with the form below to learn how Demyst helps you discover, access, license, and deploy data using our end-to-end EDaaS (External Data as a Service) offering, or if you have any other general inquiries.",
  },
  request: {
    title: "Schedule a Demo",
    description:
      "Schedule a Demo with the form below to learn how the Demyst platform empowers users to discover, access, license, and deploy external data.",
  },
  careers: {
    title: "Careers",
    description:
      "Join our team to immerse yourself in an ecosystem of strategic business challenges and opportunities and awe-inspiring data access.",
  },
  report: {
    title: "Free Discovery Report",
    description:
      "Our free data discovery report gives you a summary of the specific data sources, attributes, analytics, and integration models you can use to solve your business challenges.",
  },
  privacy: {
    title: "Privacy Policy",
    description:
      "This Privacy Policy describes what information we collect when you use our Software, how we use that information, and what choices we offer you to access, update, and control it.",
  },
  accessibility: {
    title: "Accessibility Policy",
    description:
      "This Accessibility Policy outlines the measures we have put in place to ensure that our service is inclusive and usable by as many people as possible",
  },
  terms: {
    title: "Terms and Conditions",
    description:
      "Demyst Data Limited and its affiliated companies (together, “Demyst Data” or “we” and sometimes “us”) provide the websites www.demystdev.wpengine.com and demystdata.com (collectively, the “Website”) and the Demyst Data software and libraries (collectively referred to herein as the/our “Software” or the/our “Services”), subject to your agreement to and compliance with these Terms and Conditions (the “Terms”).",
  },
  cookies: {
    title: "Cookies Policy",
    description:
      "This policy explains the different cookies used by Demyst Data on our corporate website and on our Platform (collectively, “Site”) and how you can control them. We may change this Cookie Policy at any time. Please look at the “Last Updated” date at the top of this page to see when this Cookie Policy was last revised. Any changes in this Cookie Policy will become effective when we make the revised Cookie Policy available on or through the  Site. Please see our Privacy Notice to understand the other ways we use the personal information we collect about you.",
  },
  subprocessor: {
    title: "Sub-processor Policy",
    description:
      "Demyst uses certain Sub-contractors (Processors where we act in a “Data Controller” capacity or Sub-processors where we act in a“Data Processor” capacity) to assist it in providing Demyst’s services, including the processing of personal data.",
  },
  business: {
    title: "Business Data",
    description:
      "Harness hundreds of operationalized data sources and marketplaces, through a single API.",
  },
  demystvs: {
    title: "Demyst vs. Data Consulting",
    description:
      "Demyst has a purpose built platform to connect you to the data you need and deliver it quickly into production.",
  },
  adxfincrime: {
    title: "Demyst DataOps for FinCrime",
    description:
      "Demyst has a purpose built platform to connect you to the data you need and deliver it quickly into production.",
  },
  adxpartnership: {
    title: "Demyst and AWS Data Exchange",
    description:
      "Demyst has a purpose built platform to connect you to the data you need and deliver it quickly into production.",
  },
  adxexchange: {
    title: "Demyst and AWS Data Exchange",
    description:
      "Demyst and the Amazon Web Services (AWS) Data Exchange help customers get data into production to achieve data-driven business outcomes faster.",
  },
  industries: {
    financialservices: {
      title: "Financial Services Industry",
      description:
        "Rapidly deploy the right external data solutions for financial services. Optimize your external data spend with a single platform.",
    },
    insurance: {
      title: "Insurance Industry",
      description: "Rapidly deploy the right external data solutions for insurance.",
    },
  },
  enterprise: {
    title: "Enterprise",
    description: "Delivering End-To-End Value Across The Enterprise.",
  },
  newsletter: {
    title: "Newsletter",
    description:
      "A weekly summary of Demyst product activities and general news impacting the market for external data.",
  },
  pricing: {
    title: "Pricing",
    description: "Flexible Options, Transparent Pricing. Select the right plan for your business.",
  },
  partners: {
    title: "Partners",
    description: "Flexible Options, Transparent Pricing. Select the right plan for your business.",
  },
  usecases: {
    bnpl: {
      title: "Automate Buy Now, Pay Later",
      description:
        "Access ready-to-deploy data APIs for KYC, Fraud, Compliance and Credit Decisioning , aggregating global data from multiple data partners into a single, configurable data feed.",
    },
    kyc: {
      title: "KYC Workflow Automation",
      description:
        "A single platform for seamless onboarding and verification of customers globally.",
    },
    kyb: {
      title: "KYB Workflow Automation",
      description:
        "A single platform for seamless onboarding and verification of businesses globally.",
    },
    applicationprefill: {
      title: "Application Prefill Optimization",
      description:
        "A single platform for prefilling data pertaining to people, places, and businesses, globally.",
    },
    dataenrichment: {
      title: "Enrich Leads",
      description: "A single platform to identify new leads (person, place, business) globally.",
    },
    leadtargeting: {
      title: "Optimize Lead Targeting",
      description:
        "A single platform to identify new leads, enrich leads, and priortiize leads across people, places, and businesses, globally.",
    },
    portfoliomonitoring: {
      title: "Optimize Portfolio Monitoring",
      description:
        "A single platform to enrich customer profiles for retention and cross-sell intiatives",
    },
    businessdecisioning: {
      title: "Business Customer Onboarding",
      description:
        "Build an initial business customer profile to enable improved new business onboarding decisions and minimize risk exposure.",
    },
    creditbureau: {
      title: "Optimize Credit Decisioning",
      description: "A single platform to integrate and manage credit bureau feeds globally.",
    },
  },
};
