import { SectionHeading } from "../Section";
import { CtaLinkButton } from "../Button";
import styles from "./BottomCta.module.less";

export const BottomCta = () => {
  return (
    <div className={styles.divReadyToGetStarted}>
      <div>
        <SectionHeading type="h2">Ready to get started?</SectionHeading>
        <p className={styles.lestUsSectionColor}>
          See why our customer continue to trust and leverage Demyst to accelerate their external
          data initiatives.
        </p>
      </div>
      <div>
        <CtaLinkButton
          autoWidth
          classNameDiv={styles.letUsButton}
          className={styles.letUsText}
          text={"Let us show you how"}
        />
      </div>
    </div>
  );
};
