import styles from "./Badge.module.less";
import clsx from "clsx";

import { FiCheckCircle } from "react-icons/fi";

export const Badge = ({ icon, background = "violet-1000", children }) => {
  const backgroundClassName =
    typeof background === "string"
      ? background.replace(/-/g, "_").toLowerCase()
      : typeof background === "object"
      ? background.map(bg => bg.replace(/-/g, "_").toLowerCase()).join("_to_")
      : null;
  const iconToShow = icon ? (
    typeof icon === "function" ? (
      icon()
    ) : typeof icon === "string" ? (
      <img src={icon} alt="" />
    ) : null
  ) : typeof icon === "object" ? (
    icon
  ) : (
    <FiCheckCircle />
  );
  return (
    <div className={clsx(styles.Badge, styles[backgroundClassName])}>
      <div className={styles.BadgeIcon}>{iconToShow}</div>
      <span className={styles.BadgeLabel}>{children}</span>
    </div>
  );
};
